import restaurant from "./images/res.jpeg";
import biserica from "./images/bis.jpg";
import img_card from "./images/fam.jpg";
import imgOmSuflet from "./images/5.jpg";
import imgOmSufletmb from "./images/5m.jpg";
import imgheader from "./images/2.jpg";
import img2 from "./images/noi2.jpg";
import imgheadermiini from "./images/9.jpg";
import imgheadermiinimb from "./images/9m.jpg";
import imgconfirmare from "./images/11.jpg";



const data = {
    introData: [{
        familia: "familia Zlate",
        mire: "Sorina",
        mireasa: "Dănuț",
        copil: 'Evelyn-Maria',
        data: "27 Mai 2023",
        data_confirmare: "10 mai 2023",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "marcelagheorghiu97@gmail.com", 
       tel: "+40758298602",
       phone: "tel:+40758298602",
       viber: "viber://chat?number=%2B40758298602",
       whatsapp: "https://wa.me/+40758298602",
       messenger: "https://www.messenger.com/t/100002168238000",
       tel1: "+40770216776",
       phone1: "tel:+40770216776",
       viber1: "viber://chat?number=%2B40770216776",
       whatsapp1: "https://wa.me/+40770216776",
       messenger1: "https://www.messenger.com/t/daniel.dnl.5201",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Cununia Religioasă și Sfântul Botez",
            localul: "Biserica",
            name: "Sfânta Ana",
            data: "27 mai 2023, sâmbătă,",
            ora: "ora - 14:00",
            ora1: "",
            adress: "Aleea Comerțului, Galați, România",
            harta: "https://goo.gl/maps/ZnQ4tZikQtxWMfva9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11202.575993009705!2d28.015936!3d45.416518!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x82677343845692e6!2sSt.%20Anne&#39;s%20Church!5e0!3m2!1sro!2s!4v1676980691527!5m2!1sro!2s" 
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentelor",
            localul: "Restaurantul",
            name: "Olympic Ballroom",
            data: "27 mai 2023, sâmbătă,",
            ora: "ora 19:00",
            adress: "Str. Prelungirea Foltanului nr.122, sat Costi com. Vanatori, Galați 800001, România",
            harta: "https://goo.gl/maps/wpkprP68Gcg44mQi9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11187.465309382436!2d28.0333171!3d45.4926365!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe3026027079cf23f!2sOlympic%20Ballroom!5e0!3m2!1sro!2s!4v1676981215449!5m2!1sro!2s" 
        },
       

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "Cununie și botez!",
            mami: "",
            tati: "La 2 în 1 vă poftim!",
            message: "Foaie verde de trifoi,     ",
            message1: "Super promoție la noi:",
            message2: "Chiar așa! Ce, nu ne crezi?",
            message3: "Hai să vezi că nu glumim.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "",
            title2: "Sfântul botez și Cununia Religioasă!",
            message: "Pentru că cele mai frumoase momente se sărbătoresc alături de cei dragi, vrem să fim înconjurați de cei mai apropiați și scumpi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celor mai semnificative evenimente din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            imgdesktop: imgOmSuflet,
            imgmobil: imgOmSufletmb,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Tudose și Mărioara Zlate",
            parintii_nume2: "Ionuț și Ionica Codreanu",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Mihai și Andreea Bosneaga",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă ați fi alături de noi într-o zi atât de specială!",
            message: "",
        }
    ],

    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ],

}

export default data;